import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


// import VConsole from 'vconsole' //import vconsole
// let vConsole = new VConsole() // 初始化

createApp(App).use(router).use(ElementPlus, { zIndex: 3000 }).mount("#app");
