import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import(/* webpackChunkName: "base" */ "../views/index.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import(/* webpackChunkName: "privacy" */ "../views/privacy.vue"),
  },
  {
    path: "/helpers",
    name: "Helpers",
    component: () => import(/* webpackChunkName: "helpers" */ "../views/helpers.vue"),
  },
  {
    path: "/scanCode",
    name: "ScanCode",
    component: () => import(/* webpackChunkName: "scanCode" */ "../views/scanCode.vue"),
  },
  {
    path: "/question",
    component: () => import(/* webpackChunkName: "question" */ "../views/question"),
    // redirect: '/home',
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "question" */ "../views/question/homePage"),
      },
      {
        path: 'kind',
        name: 'problemKind',
        component: () => import(/* webpackChunkName: "question" */ "../views/question/problemKind"),
      },
      {
        path: 'specific',
        name: 'Specific',
        component: () => import(/* webpackChunkName: "question" */ "../views/question/specific"),
      }
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
